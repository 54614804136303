import { CurrencyPipe, DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { PlanCashflowChartConfig } from 'src/app/iris-components/charts/cashflow-chart/chart-config';
import { LoanUnpaidBalanceChartConfig } from 'src/app/iris-components/charts/loan-unpaid-balance-chart/chart-config';
import { LoansCreatedChartConfig } from 'src/app/iris-components/charts/loans-created-chart/chart-config';
import { LoansCreatedChartComponent } from 'src/app/iris-components/charts/loans-created-chart/loans-created-chart.component';
import { Client } from 'src/app/models/client';
import { AdvisorStateService } from 'src/app/services/advisor-state.service';
import { AuthService } from 'src/app/services/auth.service';
import { Timeline } from '../timeline/timeline';
import { PlanResultsUtil } from './plan-results-util';

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss'],
})
export class PlanComponent implements OnInit {
  client?: Client;

  results?: any;

  loading = false;
  debug = false;

  timelineResults: any;
  planOptimizerStatus: any;

  isVisibleInfo = false;

  constructor(
    private httpClient: HttpClient,
    private modalService: NzModalService,

    private advisorStateService: AdvisorStateService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.client = this.advisorStateService.getClient();
    if (this.client!.plan && this.client!.plan.results) {
      this.results = this.client!.plan!.results;
      this.timelineResults = Timeline.timelineResults(this.results);
      this.planOptimizerStatus = PlanResultsUtil.planOptimizerStatus(this.client);
    }

    this.updateChart();
  }

  loanUnpaidBalanceChartConfig?: LoanUnpaidBalanceChartConfig;
  planCashflowChartConfig?: PlanCashflowChartConfig;
  loansCreatedChartConfig?: LoansCreatedChartConfig;

  updateChart() {
    const resultDataSeries = this.results.data ?? [];
    if (this.results) {
      this.loanUnpaidBalanceChartConfig = {
        data: resultDataSeries.map((r: any) => r['upb_start']),
        labels: resultDataSeries.map((r: any) => r.label),
        xAxisScaleLabel: 'Years',
        yAxisScaleLabel: 'Amount',
        seriesLabel: 'Loan Unpaid Balance',
        currency: 'USD',
      };
      this.planCashflowChartConfig = {
        totalLoansCfValues: resultDataSeries.map(
          (r: any) => r['total loans CF']
        ),
        passiveIncomeValues: resultDataSeries.map((r: any) => r['passive']),
        contributionValues: resultDataSeries.map((r: any) => r['contribution']),

        labels: new Array(resultDataSeries.length)
          .fill(null)
          .map((_, i) => `${i}`),
        xAxisScaleLabel: 'Year',
        yAxisScaleLabel: 'Amount',
        currency: 'USD',
      };
      var sum = 0;

      this.loansCreatedChartConfig = {
        data: resultDataSeries.map((r: any) => r['total loans']),
        contributions: resultDataSeries.map((r: any) => {
          sum += r['contribution'];
          return sum }), //map((r:any) => r['contribution']),
        labels: resultDataSeries.map((r: any) => r.label),
        xAxisScaleLabel: 'Years',
        yAxisScaleLabel: 'Amount',
        seriesLabel: 'Loans Created',
        currency: 'USD',
      };
    } else {
      this.loanUnpaidBalanceChartConfig = undefined;
      this.planCashflowChartConfig = undefined;
      this.loansCreatedChartConfig = undefined;
    }
  }

  stringify(data: any) {
    return JSON.stringify(data);
  }

  openInfo() {
    this.isVisibleInfo = true;
  }

  handleOk() {
    this.isVisibleInfo = false;
  }

  handleCancel() {
    this.isVisibleInfo = false;
  }

  get freedomDate() {
    if (this.client!.plan!.results!.msgs['freedom_date']) {
      let date = new Date(this.client?.plan?.results?.msgs['freedom_date']);
      return `${new DatePipe('en-US').transform(date, 'mediumDate')}`;
    } else {
      return 'N/A';
    }
  }

  get passiveIncome() {
    if (this.client!.plan!.results!.msgs['passive_income']) {
      return `${new CurrencyPipe('en-US').transform(this.client!.plan!.results!.msgs['passive_income'], 'USD','$','0.0-2')}`;
    } else {
      return 'N/A';
    }
  }

}
