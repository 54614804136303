<div class="plan-page" *ngIf="client && results">
  <div class="results-header">
    <h3 class="results-header__title">Income Plan Results</h3>
    <div *ngIf="planOptimizerStatus" class="optimization">
      <h5>{{ planOptimizerStatus.message }}</h5>
      <nz-progress
        [nzPercent]="planOptimizerStatus.progress"
        [nzShowInfo]="false"
        [nzStrokeColor]="planOptimizerStatus.color"
      ></nz-progress>
    </div>
    <div *ngIf="timelineResults" class="sustainable">
      <h5>{{ timelineResults.message }}</h5>
      <nz-progress
        [nzPercent]="timelineResults.progress"
        [nzShowInfo]="false"
        [nzStrokeColor]="timelineResults.color"
      ></nz-progress>
    </div>
  </div>
  <div class="results-tiles">
    <div class="results-tiles__tile">
      <img src="/assets/img/plan-results/send.svg" />
      <nz-statistic
        nz-col
        [nzValue]="freedomDate"
        [nzTitle]="'Financial Freedom Date'"
        [nzValueStyle]="{ color: '#130F26' }"
      ></nz-statistic>
    </div>
    <div class="results-tiles__tile">
      <img src="/assets/img/plan-results/dollar.svg" />
      <nz-statistic
        [nzValue]="
          (results.msgs['contribution'] | currency: 'USD':'$':'0.0-2')!
        "
        [nzTitle]="'Monthly Contribution'"
        [nzValueStyle]="{ color: '#130F26' }"
      ></nz-statistic>
    </div>
    <div class="results-tiles__tile">
      <img src="/assets/img/plan-results/wallet.svg" />
      <nz-statistic
        [nzValue]="passiveIncome"
        [nzTitle]="'Passive Income'"
        [nzValueStyle]="{ color: '#130F26' }"
      ></nz-statistic>
    </div>
    <div class="results-tiles__tile">
      <img src="/assets/img/plan-results/loc.svg" />
      <nz-statistic
        [nzValue]="(results.msgs['loc_size'] | currency: 'USD':'$':'0.0-2')!"
        [nzTitle]="'Starting LOC Size'"
        [nzValueStyle]="{ color: '#130F26' }"
      ></nz-statistic>
    </div>
    <div class="results-tiles__tile">
      <img src="/assets/img/plan-results/principal.svg" />
      <nz-statistic
        [nzValue]="
          (results.msgs['principal_invested'] | currency: 'USD':'$':'0.0-2')!
        "
        [nzTitle]="'Principal Amount'"
        [nzValueStyle]="{ color: '#130F26' }"
      ></nz-statistic>
    </div>

    <button
      class="results-tiles__more"
      nz-button
      nzType="text"
      (click)="openInfo()"
    >
      ...
    </button>
  </div>
  <nz-tabset
    class="plan-page__charts"
    [nzType]="'card'"
    [nzTabBarGutter]="12"
    [nzTabBarStyle]="{ margin: '12px 0' }"
  >
    <nz-tab nzTitle="Cashflow">
      <nz-card>
        <div nz-row>
          <app-cashflow-chart nz-col nzSpan="18" style="padding-right: 10px"
            *ngIf="planCashflowChartConfig"
            [config]="planCashflowChartConfig"
          ></app-cashflow-chart>
          <div nz-col nzSpan="6" style="border-left: 1px solid lightgray; padding-left: 10px">
            <h3>Key Metrics</h3>
            <nz-statistic class="key-metrics"
              [nzValue]="
                (results.msgs['passive_income'] | currency: 'USD':'$':'0.0-2')!
              "
              [nzTitle]="'Passive Income'"
              [nzValueStyle]="{ color: '#130F26' }"
            ></nz-statistic>
            <nz-statistic class="key-metrics"
              [nzValue]="
                (results.msgs['contribution'] | currency: 'USD':'$':'0.0-2')!
              "
              [nzTitle]="'Monthly Contribution'"
              [nzValueStyle]="{ color: '#130F26' }"
            ></nz-statistic>
            <nz-statistic class="key-metrics"
              [nzValue]="
                (results.msgs['total_cf_for_month'] | currency: 'USD':'$':'0.0-2')!
              "
              [nzTitle]="'Monthly Cashflow on Freedom Day'"
              [nzValueStyle]="{ color: '#130F26' }"
            ></nz-statistic>
          </div>
        </div>
      </nz-card>
    </nz-tab>
    <nz-tab nzTitle="Unpaid Balance">
      <nz-card>
        <div nz-row>
          <app-loan-unpaid-balance-chart nz-col nzSpan="18" style="padding-right: 10px"
            *ngIf="loanUnpaidBalanceChartConfig"
            [config]="loanUnpaidBalanceChartConfig"
          ></app-loan-unpaid-balance-chart>
          <div nz-col nzSpan="6" style="border-left: 1px solid lightgray; padding-left: 10px">
            <h3>Key Metrics</h3>
            <nz-statistic class="key-metrics"
              [nzValue]="
                (results.msgs['freedom_upb'] | currency: 'USD':'$':'0.0-2')!
              "
              [nzTitle]="'Financial Freedom Day UPB'"
              [nzValueStyle]="{ color: '#130F26' }"
            ></nz-statistic>
            <nz-statistic class="key-metrics"
              [nzValue]="
                (results.msgs['freedom_assets_created'] | currency: 'USD':'$':'0.0-2')!
              "
              [nzTitle]="'Financial Freedom Day Assets Created'"
              [nzValueStyle]="{ color: '#130F26' }"
            ></nz-statistic>
            <nz-statistic class="key-metrics"
              [nzValue]="
                (results.msgs['principal_invested'] | currency: 'USD':'$':'0.0-2')!
              "
              [nzTitle]="'Principal Invested'"
              [nzValueStyle]="{ color: '#130F26' }"
            ></nz-statistic>
            <nz-statistic class="key-metrics"
              [nzValue]="
                (results.msgs['final_net_worth']  | currency: 'USD':'$':'0.0-2')!
              "
              [nzTitle]="'Final Net Worth'"
              [nzValueStyle]="{ color: '#130F26' }"
            ></nz-statistic>
          </div>
        </div>
      </nz-card>
    </nz-tab>
    <nz-tab nzTitle="Investment to Asset">
      <nz-card>
        <div nz-row>
          <app-loans-created-chart nz-col nzSpan="18" style="padding-right: 10px"
            *ngIf="loansCreatedChartConfig"
            [config]="loansCreatedChartConfig"
          ></app-loans-created-chart>
          <div nz-col nzSpan="6" style="border-left: 1px solid lightgray; padding-left: 10px">
            <h3>Key Metrics</h3>
            <nz-statistic class="key-metrics"
              [nzValue]="
                (results.msgs['principal_invested'] | currency: 'USD':'$':'0.0-2')!
              "
              [nzTitle]="'Principal Invested'"
              [nzValueStyle]="{ color: '#130F26' }"
            ></nz-statistic>
            <nz-statistic class="key-metrics"
              [nzValue]="
                (results.msgs['freedom_assets_created'] | currency: 'USD':'$':'0.0-2')!
              "
              [nzTitle]="'Assets Created'"
              [nzValueStyle]="{ color: '#130F26' }"
            ></nz-statistic>
            <nz-statistic class="key-metrics"
              [nzValue]="
                ( results.msgs['freedom_in'] )!
              "
              [nzTitle]="'Freedom Day In'"
              [nzValueStyle]="{ color: '#130F26' }"
            ></nz-statistic>
          </div>
        </div>
      </nz-card>
    </nz-tab>
    <nz-tab nzTitle="Timeline">
      <app-timeline [client]="client" style="min-height: 440px"></app-timeline>
    </nz-tab>
  </nz-tabset>
  <div nz-row [nzGutter]="16">
    <div nz-col nz-col nzSpan="8" class="gutter-row" style="display: flex; flex-direction: column;">
      <div class="plan-page__card-title">Flip Schedule</div>
      <nz-card style="height: 100%" [nzBodyStyle]="{'padding': '0px'}">
        <div *ngIf="results.flipschedule">
          <nz-table #flipTable [nzData]="results.flipschedule" [nzPageSize]="4">
            <thead>
              <tr>
                <th>Flip No.</th>
                <th>Month</th>
                <th>Date</th>
                <th>Type</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of flipTable.data; let i = index">
                <td>
                  {{
                    (flipTable.nzPageIndex - 1) * flipTable.nzPageSize + i + 1
                      | number: "3.0-0"
                  }}
                </td>
                <td>{{ data.month }}</td>
                <td>{{ data.date | date: "mediumDate" }}</td>
                <td>{{ data.type == 0 ? "LOC" : "Cash" }}</td>
                <td>{{ data.loc_size | currency: "USD":"$":"0.0-2" }}</td>
              </tr>
            </tbody>
          </nz-table>
        </div>
      </nz-card>
    </div>
    <div nz-col nzSpan="8" style="display: flex; flex-direction: column;">
      <div class="plan-page__card-title">Monthly Cash Flow</div>
      <nz-card style="height: 100%" [nzBodyStyle]="{padding: '0'}">
        <app-cash-flow [client]="client" style="height: 100%"></app-cash-flow>
      </nz-card>
    </div>
    <div nz-col nzSpan="8" style="display: flex; flex-direction: column;">
      <div class="plan-page__card-title">Scheduled Transactions</div>
      <nz-card style="height: 100%" [nzBodyStyle]="{padding: '0'}">
        <div *ngIf="!client.transactions || client.transactions.length == 0">
          No Transactions
        </div>
        <nz-table *ngIf="client.transactions && client.transactions.length > 0"
          #transactionsTable
          [nzData]="client.transactions"
          [nzPageSize]="4"
        >
          <thead>
            <tr>
              <th>Date</th>
              <th>Event</th>
              <th>Type</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let transaction of transactionsTable.data; let i = index">
              <td>{{ transaction.startDate | date: "mediumDate" }}</td>
              <td>{{ transaction.title }}</td>
              <td>
                <nz-tag *ngIf="transaction.type == 'DIVERT'" nzColor="#060A2B">
                  Divert
                </nz-tag>
                <nz-tag *ngIf="transaction.type == 'DEPOSIT'" nzColor="#06A7F5">
                  Deposit
                </nz-tag>
                <nz-tag *ngIf="transaction.type == 'DRAW'" nzColor="#060A2B">
                  Draw
                </nz-tag>
              </td>
              <td>
                {{ transaction.amount | currency: "USD":"$":"1.0-2" }}
              </td>
            </tr>
          </tbody>
        </nz-table>
      </nz-card>
    </div>
  </div>
  <div>
    <div class="plan-page__card-title">Detailed Monthly Results</div>
    <nz-card [nzBodyStyle]="{padding: '0'}" >
      <div *ngIf="results.data">
        <nz-table
          #dataTable
          [nzData]="results.data"
          [nzPageSize]="12"
          [nzScroll]="{ x: '1500px' }"
        >
          <thead>
            <tr>
              <th nzLeft>Date</th>
              <th>LOC Flips</th>
              <th>Cash Flips</th>
              <th>Loan Size</th>
              <th>Total Loans Created</th>
              <th>Loans Start UPB</th>
              <th>Total CF</th>
              <th>Contribution</th>
              <th>LOC Size</th>
              <th>LOC Start Balance</th>
              <th>LOC End Balance</th>
              <th>Passive Income</th>
              <th>Divert Amount</th>
              <th>Divert Title</th>
              <th>Draw Amount</th>
              <th>Draw Title</th>
              <th>Pay Down</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of dataTable.data; let i = index">
              <td
                nzLeft
                [ngClass]="{
                  'data-green': data.phase == 3,
                  'data-red': data.phase == 1
                }"
              >
                {{ data.date | date: "mediumDate" }}
              </td>
              <td>{{ data.loc_flips }}</td>
              <td>{{ data.cash_flips }}</td>
              <td>{{ data["loan size"] | currency: "USD":"$":"0.0-2" }}</td>
              <td>{{ data["total loans"] | currency: "USD":"$":"0.0-2" }}</td>
              <td>{{ data.upb_start | currency: "USD":"$":"0.0-2" }}</td>
              <td>
                {{ data["total loans CF"] | currency: "USD":"$":"0.0-2" }}
              </td>
              <td>{{ data.contribution | currency: "USD":"$":"0.0-2" }}</td>
              <td>{{ data["loc size"] | currency: "USD":"$":"0.0-2" }}</td>
              <td>
                {{ data.loc_start_balance | currency: "USD":"$":"0.0-2" }}
              </td>
              <td>
                {{ data.loc_end_balance | currency: "USD":"$":"0.0-2" }}
              </td>
              <td>{{ data.passive | currency: "USD":"$":"0.0-2" }}</td>
              <td>
                {{ data.diverted_amount | currency: "USD":"$":"0.0-2" }}
              </td>
              <td>{{ data.diverted_title }}</td>
              <td>{{ data.locdraw_amount | currency: "USD":"$":"0.0-2" }}</td>
              <td>{{ data.locdraw_title }}</td>
              <td>
                {{ data.locpaydown_amount | currency: "USD":"$":"0.0-2" }}
              </td>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </nz-card>
  </div>
</div>
<app-loading *ngIf="loading"></app-loading>

<nz-modal
  [nzStyle]="{ top: '20px' }"
  [(nzVisible)]="isVisibleInfo"
  nzTitle="Info"
  (nzOnCancel)="handleCancel()"
  (nzOnOk)="handleOk()"
>
  <ng-container *nzModalContent>
    <h5>Overview</h5>
    <table *ngIf="results.msgs" style="width: 100%">
      <tr>
        <td style="width: 50%">
          <b>Simulation Years</b>
        </td>
        <td>{{ results.msgs["sim_years"] }} years</td>
      </tr>
      <tr *ngIf="results.msgs['freedom_in']">
        <td>
          <b>Financial Freedom Day</b>
        </td>
        <td>
          In {{ results.msgs["freedom_in"] }} (in
          {{ results.msgs["freedom_months"] }} months)
        </td>
      </tr>
      <tr *ngIf="results.msgs['freedom_in']">
        <td>
          <b>Financial Freedom Day</b>
        </td>
        <td>Date {{ freedomDate | date: "mediumDate" }}</td>
      </tr>
    </table>
    <h5 style="margin-top: 20px">Freedom Day</h5>
    <table style="width: 100%">
      <tr *ngIf="results.msgs['freedom_in']">
        <td style="width: 50%">
          <b>Financial Freedom Day UPB</b>
        </td>
        <td>
          {{ results.msgs["freedom_upb"] | currency: "USD":"$":"0.0-2" }}
        </td>
      </tr>
      <tr *ngIf="results.msgs['freedom_in']">
        <td>
          <b>Financial Freedom Day Assets Created</b>
        </td>
        <td>
          {{
            results.msgs["freedom_assets_created"] | currency: "USD":"$":"0.0-2"
          }}
        </td>
      </tr>
      <tr *ngIf="results.msgs['freedom_in']">
        <td>
          <b>Financial Freedom Day LOC Size</b>
        </td>
        <td>
          {{ results.msgs["freedom_loc_size"] | currency: "USD":"$":"0.0" }}
        </td>
      </tr>
      <tr *ngIf="results.msgs['freedom_in']">
        <td>
          <b>Financial Freedom Day Monthly Cash Flow</b>
        </td>
        <td>
          {{ results.msgs["total_cf_for_month"] | currency: "USD":"$":"0.0" }}
        </td>
      </tr>

      <!-- else -->
      <tr *ngIf="!results.msgs['freedom_in']">
        <td>
          <b>Financial Freedom Day</b>
        </td>
        <td>Cannot be achieved in the time frame specified.</td>
      </tr>
    </table>
    <h5 style="margin-top: 20px">Contributions and Passive Income</h5>
    <table style="width: 100%">
      <tr
        *ngIf="
          results.msgs['freedom_in'] && results.msgs['freedom_total_months']
        "
      >
        <td style="width: 50%">
          <b>Starting LOC Size</b>
        </td>
        <td>
          {{ results.msgs["loc_size"] | currency: "USD":"$":"0.0-2" }}
        </td>
      </tr>
      <tr
        *ngIf="
          results.msgs['freedom_in'] && results.msgs['freedom_total_months']
        "
      >
        <td style="width: 50%">
          <b>Duration of Passive Income</b>
        </td>
        <td>
          {{ results.msgs["freedom_total_years"] }} ({{
            results.msgs["freedom_total_months"]
          }}
          months)
        </td>
      </tr>

      <tr>
        <td>
          <b>Passive Income</b>
        </td>
        <td>
          <span *ngIf="results.msgs['passive_income']">
            {{ results.msgs["passive_income"] | currency: "USD":"$":"0.0-2" }}
            <span
              *ngIf="results.msgs['inflation'] && results.msgs['inflation'] > 0"
              >(inflation-adjusted to {{ results.msgs["inflation"] * 100 }} %
              )</span
            >

            <span
              *ngIf="
                results.msgs['inflation'] && results.msgs['inflation'] == 0
              "
              >(not accounting for inflation)</span
            >
          </span>

          <span *ngIf="!results.msgs['passive_income']"> None </span>
        </td>
      </tr>
      <tr>
        <td>
          <span *ngIf="results.msgs['freedom_in']">
            <b>Principal Invested</b> (by Financial Freedom Day)
          </span>
          <span *ngIf="!results.msgs['freedom_in']">
            <b>Principal Invested</b> (during
            {{ results.msgs["sim_years"] }} -year simulation)
          </span>
        </td>
        <td>
          {{ results.msgs["principal_invested"] | currency: "USD":"$":"0.0-2" }}
        </td>
      </tr>
      <tr *ngIf="results.msgs['contribution_in']">
        <td>
          <b>Contribution Ends In</b>
        </td>
        <td>
          {{ results.msgs["contribution_in"] }}
        </td>
      </tr>
      <tr *ngIf="results.msgs['contribution_in']">
        <td>
          <b>Contribution Ends On</b>
        </td>
        <td>
          {{ results.msgs["contribution_date"] }}
        </td>
      </tr>
      <tr *ngIf="!results.msgs['contribution_in']">
        <td>
          <b>Contribution Ends In</b>
        </td>
        <td>Doesn't end in the time frame specified.</td>
      </tr>

      <tr>
        <td>
          <b>Cash Flow End Date</b>
        </td>
        <td>
          <span *ngIf="results.msgs['end_in']">
            {{ results.msgs["end_in"] }} (date:
            {{ results.msgs["end_date"] }}
          </span>
          <span *ngIf="!results.msgs['end_in']">
            The cash flow does NOT end within the time frame specified.
          </span>
        </td>
      </tr>
      <tr *ngIf="results.msgs['final_loan_upb']">
        <td><b>Final Net Worth</b> (Final UPB - LOC Balance)</td>
        <td>
          {{ results.msgs["final_loan_upb"] | currency: "USD":"$":"0.0-2" }}
          -
          {{ results.msgs["final_loc_size"] | currency: "USD":"$":"0.0-2" }}
          =
          {{ results.msgs["final_net_worth"] | currency: "USD":"$":"0.0-2" }}
        </td>
      </tr>
    </table>

    <h5 style="margin-top: 20px">Results</h5>
    <table style="width: 100%">
      <tr>
        <td style="width: 50%">
          <b>Flip Count</b> (during {{ results.msgs["sim_years"] }}-year
          simulation)
        </td>
        <td>
          {{ results.msgs["flip_count"] }}
        </td>
      </tr>
      <tr>
        <td>
          <b>For internal use only (PCounters)</b>
        </td>
        <td>
          {{ results.msgs["phase1_count"] }},
          {{ results.msgs["phase2_count"] }},
          {{ results.msgs["phase3_count"] }}
        </td>
      </tr>
    </table>
  </ng-container>
</nz-modal>
